/* Footer Section */
.footer {
    background-color: #12531e;
    color: white;
    padding: 40px 20px;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: first baseline;
    flex-wrap: wrap; /* To handle responsiveness */
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
  }
  
  /* Right-side column */
  .footer-column-right {
    margin-left: auto; /* Push the Checkatrade logo to the right */
  }
  
  .footer-logo {
    width: 180px; /* Adjust width for logos */
    margin-bottom: 20px;
  }
  
  .footer-hours h2,
  .footer-address {
    margin-top: 20px;
  }
  
  .footer-hours p,
  .footer-address p {
    margin: 5px 0;
  }
  
  .social-buttons {
    display: flex;
    gap: 10px;
  }
  
  .social-buttons .facebook-button {
    background-color: #3b5998;
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .social-buttons .facebook-button:hover {
    background-color: #2d4373;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icon {
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  .social-icon:hover {
    color: #ccc; /* Change color on hover */
  }
  
  @media (max-width: 768px) {
    .footer-column {
      width: 100%; /* Make columns stack on small screens */
      align-items: center; /* Center align on small screens */
      text-align: center; /* Center text on small screens */
    }
  }
  