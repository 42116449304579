.comments-section {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  
  .comments-slider-wrapper {
    overflow: hidden;
    width: 100%;
  }
  
  .comments-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .comment {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    box-sizing: border-box;
    border-radius: 0px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .comment-content {
    background-color: white;
    padding: 40px 20px;
    font-size: 1rem;
    text-align: center;
    border-bottom: 5px solid #2EC866;
  }
  
  .comment-meta {
    background-color: #6ce799;
    display: flex;
    align-items: center;
    padding: 20px;
    color: white;
    font-size: 1rem;
    justify-content: center;
  }
  
  .comment-meta img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  
  .arrow-left, .arrow-right {
    background-color: #c7cac7;
    color: white;
    border: none;
    padding: 10px;
    font-size: 2rem;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .arrow-left:hover, .arrow-right:hover {
    background-color: #28B356;
  }
  
  .arrow-left {
    position: absolute;
    left: -60px;
  }
  
  .arrow-right {
    position: absolute;
    right: -60px;
  }
  