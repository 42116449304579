/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #12531e;
  color: white;
  position: relative;
  z-index: 20;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  list-style: none;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #ccc;
}


.btn {
  padding: 6px 16px;
  border-radius: 24px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none!important;
}

.contact-us {
  background-color: #2EC866;
  color: white;
  border: none;
  text-decoration: none !important; /* Forcefully remove underline */
  outline: none; /* Remove any focus outline */
}

.contact-us:hover,
.contact-us:focus,
.contact-us:active {
    background-color: #28B356;
    text-decoration: none !important; /* Ensure underline is not present on hover, focus, or active states */
    outline: none;
}

/* Hamburger icon for mobile */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle .bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

/* Mobile view styles */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #12531e;
    width: 100%;
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
  }

  .navbar-links.active {
    display: flex;
    z-index: 10; /* Ensures the dropdown is above other content */
  }

  .navbar-links li {
    margin: 20px 0;
  }

  .navbar-toggle {
    display: flex;
  }
}
