/* Help Section */
.help-section {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  /* Image Container */
  .help-image-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px; 
  }
  
  .help-image {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in-out; /* Smooth zoom effect */
  }
  
  .help-image-container:hover .help-image {
    transform: scale(1.1); /* Zoom in on hover */
  }
  
  /* Text Container */
  .help-text-container {
    text-align: center;
    padding: 20px;
    background-color: #fff;
  }
  
  .help-text-container h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .help-text-container p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .help-button {
    background-color: #12531e;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    border: 1px solid #12531e;
    transition: background-color 0.3s ease;
  }
  
  .help-button:hover {
    background-color: white;
    color: #12531e;
  }
  
  /* Full-width image section */
  .help-image-container {
    width: 100vw; /* Make the image take the full width of the viewport */
    max-width: 100%;
    margin: 0 auto; /* Center the image horizontally */
    overflow: hidden;
  }
  
  .help-image {
    width: 100%;
    display: block; /* Ensure the image is not treated as an inline element */
    height: auto;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .help-text-container h2 {
      font-size: 1.5rem;
    }
  
    .help-text-container p {
      font-size: 1rem;
    }
  
    .help-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  