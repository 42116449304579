/* Styles for dark mode */
body.dark-mode {
  background-color: #121212; /* Dark background color */
  color: #ffffff; /* Light text color */
}

body.dark-mode a {
  color: #4caf50; /* Change link color in dark mode */
}

body.dark-mode h1, 
body.dark-mode h2, 
body.dark-mode h3, 
body.dark-mode h4, 
body.dark-mode h5, 
body.dark-mode h6 {
  color: #ffffff; /* Ensure headings are visible in dark mode */
}

body.dark-mode .toggle-button {
  background-color: #333;
  color: #fff;
}

body.dark-mode .navbar, /* Example: make navbar background dark */
body.dark-mode .footer {
  background-color: #1e1e1e;
}

body.dark-mode .card {
  background-color: #2c2c2c;
  color: #fff;
  border: 1px solid #444;
}
