.hero {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out;
  opacity: 0; /* Hidden by default */
}

.hero-background.background1 {
  z-index: 1;
  opacity: 1; /* Start with background1 visible */
}

.hero-background.background2 {
  z-index: 0;
}

.hero-content {
  z-index: 2;
  position: relative;
}

.hero-button {
  background-color: #12531e;
  color: white;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
}

.hero-button:hover {
  background-color: white;
  color: #12531e;
}
