/* Contact Form Styles */
.contact-section {
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .contact-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .contact-section form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-section label {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    width: 300px;
  }
  
  .contact-section input,
  .contact-section textarea {
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-section button {
    padding: 10px 20px;
    background-color: #12531e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .contact-section button:hover {
    background-color: #2a6a36;
  }
  